<template>
    <div class="go_withdrawal">
        <div class="back">
            <i class="iconfont icon-back" @click="$router.go(-1)"></i>
            <span>提现</span>
        </div>
        <div>
            <div class="tips">提示：提现日期为每个月2号到10号，最低提现金额200元。</div>
            <div class="step_next">
                <el-button type="primary" @click="get_money">申请提现</el-button>
            </div>
        </div>
    </div>
</template>
<script>
import {go_withdraw} from "@/https/mine_center.js";

export default {
    name: 'go_withdrawal',
    components: {},
    data() {
        return {
            form: {
                money: ''
            },
            from_rules: {
                money: [
                    {required: true, type: 'number', message: "提现金额不能为空", trigger: "blur"},
                    {required: true, type: 'number', message: "提现金额不能小于100", min: 100, trigger: "blur"},
                    {
                        required: true,
                        type: 'number',
                        message: "提现金额不能大于最大提现金额",
                        validator: this.max_pay,
                        trigger: "blur"
                    },
                ]
            },
            max_money: 0
        }
    },
    watch: {
        'form.money'(n, v) {
            if (n > this.max_money) {
                this.form.money = this.max_money
            }
            if (n < 0) {
                this.form.money = 0
            }
        }
    },
    created() {
        this.aviod_big_money()
    },
    methods: {
        max_pay(rule, value, callback) {
            if (value > this.max_money) {
                callback(new Error('提现金额不能超过最大余额'));
            }
            callback()
        },
        aviod_big_money() {
            let params = {
                type: 1
            }
            go_withdraw(params).then((res) => {
                if (res.code === 0) {
                    this.max_money = Number(res.data.money)
                    // this.max_money=5
                }
            })
        },
        get_money() {
            let params = {
                type: 2
            }
            go_withdraw(params).then((res) => {
                if (res.code === 0) {
                    this.$message.success('提现成功')
                    // this.$router.go(0)
                    window.location.reload()
                } else {
                    this.$message.warning(res.msg)
                }
            })

        }
    }
}
</script>
<style lang="scss" scoped>
@import "../../../utils/tool.scss";
// @include flex-center();
.go_withdrawal {
    @include flex-column();

    .tips{
        text-align: center;
    }

    .back {
        padding: 15px 0;
        @include flex-row();

        i {
            margin-right: 10px;
            cursor: pointer;

            &:hover {
                color: #409eff
            }
        }
    }
}

.step_next {
    @include flex-center();
    padding: 15px 0;

    /deep/ .el-button {
        padding: 8px 15px
    }
}

/deep/ .el-form-item__label {
    width: 160px !important
}

/deep/ .el-form-item__content {
    margin-left: 160px !important;
}
</style>